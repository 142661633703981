export const CURRENT_MENU_ITEM_CONTEXT = "menu:current-item";
export const KITCHEN_REPORT_CONTEXT = "restaurant:kitchen-report";
export const PAGE_META_CONTEXT = "page:meta";

/**
 * Posthog custom event keys
 */
export const POSTHOG_CAPTURE = {
	FILTER_OPENED: "filter_opened",
	MATRIX_OPENED: "matrix_opened",
	HIDDEN_ITEMS_OPENED: "hidden_items_opened",
};

/**
 * Posthog custom identifiers
 */
export const POSTHOG_REGISTER = {
	APP: "edible-app",
	RESTAURANT_ID: "restaurantId",
	RESTAURANT_SLUG: "restaurantSlug",
	MENU_ID: "menuId",
	MENU_SLUG: "menuSlug",
};
