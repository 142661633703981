import { writable } from "svelte/store";
/**
 * Creates a custom store for managing a collection of 'active' allergens
 * @param initialAllergens - An initial array of allergens
 * @returns An Active Allergen store
 */
export const createActiveDietStore = (initialAllergens = []) => {
    const allergenWritable = writable(initialAllergens);
    const { update, set } = allergenWritable;
    /**
     * Pushes a new allergen to the collection
     */
    const add = (allergen) => update((allergens) => [...allergens, allergen]);
    /**
     * Removes an allergen from the collection
     */
    const remove = (filterAllergen) => update((allergens) => allergens.filter((existingAllergen) => existingAllergen !== filterAllergen));
    /**
     * Sets the collection to the passed array of allergens,
     * or resets it if an empty array is passed
     */
    const reset = (allergens) => set(allergens);
    return {
        subscribe: allergenWritable.subscribe,
        reset,
        add,
        remove,
    };
};
