import { writable } from "svelte/store";
import { factory as storesFactories } from "@edible/apps-ui/viewer-stores";

const createPageMetaStore = (defaultTitle: string, defaultDescription: string) => {
	const store = writable({ page_title: defaultTitle, page_description: defaultDescription });

	return {
		subscribe: store.subscribe,
		set: store.set,
	};
};

export const filter = storesFactories.createFilterStore();
export const factory = { ...storesFactories, createPageMetaStore };
