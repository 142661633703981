import { derived, writable } from "svelte/store";
import { getAllergenCategories } from "@edible/shared";
import { getFilterFacts } from "../selectors/filter";
import { createActiveDietStore } from "./diet";
/**
 * Creates a custom store for a filter
 * @param allergens - Initial allergens list
 * @param diets - Initial diets list
 * @returns A filter store
 */
export const createFilterStore = (allergens, diets) => {
    const activeAllergens = createActiveDietStore(allergens);
    const activeDiets = createActiveDietStore(diets);
    const hasAcknowledgedPolicy = writable(false);
    const filter = derived([activeAllergens, activeDiets, hasAcknowledgedPolicy], ([$allergens, $diets, $hasAcknowledgedPolicy]) => {
        const filterFacts = getFilterFacts($allergens, $diets);
        const allergenCategories = getAllergenCategories($allergens);
        return {
            allergens: $allergens,
            allergenCategories,
            diets: $diets,
            hasAcknowledgedPolicy: $hasAcknowledgedPolicy,
            ...filterFacts,
        };
    });
    return {
        subscribe: filter.subscribe,
        allergens: {
            add: activeAllergens.add,
            remove: activeAllergens.remove,
            reset: activeAllergens.reset,
        },
        diets: {
            add: activeDiets.add,
            remove: activeDiets.remove,
            reset: activeDiets.reset,
        },
        acknowledgePolicy: () => hasAcknowledgedPolicy.set(true),
    };
};
