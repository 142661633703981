import type { FilterSpec } from "../types/filter.js";
import type { TranslationFunctions } from "../i18n/i18n-types.js";
import type { LocalizedString } from "typesafe-i18n";

type FilterTranslationsFunctions = TranslationFunctions["filter"];
type FilterType = keyof FilterTranslationsFunctions;

const translateFilterId =
	<T extends FilterType, D extends FilterTranslationsFunctions[T], I extends keyof D>(type: T) =>
	(translationFns: TranslationFunctions) =>
	(identifier: I) => {
		const dictionary = translationFns.filter[type] as D;

		const labelFn = dictionary[identifier] as () => LocalizedString;

		return {
			id: identifier,
			label: labelFn() as LocalizedString | string,
		};
	};

const translateDietId = translateFilterId("diet");
const translateAllergenId = translateFilterId("allergen");

export const dietOptions = (
	dietSpec: FilterSpec["diets"],
	translationFns: TranslationFunctions,
) => {
	const dietOption = translateDietId(translationFns);

	return dietSpec.map(dietOption);
};
export const allergenOptions = (
	allergenSpec: FilterSpec["allergens"],
	translationFns: TranslationFunctions,
) => {
	const allergenOption = translateAllergenId(translationFns);

	return allergenSpec.map(({ id, members }) => ({
		...allergenOption(id),
		members: members.map(allergenOption),
	}));
};

/**
 * Allergen id's will contain parent categories, e.g `legume-peanut` (and potentially in future `legume-bean-kidney`)
 * This will create an array of "-" separated strings ["legume", "bean", "kidney"] and drop the last value, leaving only categories
 * The resulting array of arrays [["legume"], ["legume", "bean"]] is flattened and a new Set is used to de-duplicate values
 *
 * TODO: in order for us to use a stricter type here, AllergenId[], we need to make our form schemas that work with ids stricter
 * see approaches here https://stackoverflow.com/questions/75317224/how-to-validate-a-string-literal-type-using-zod
 */
export const getAllergenCategories = (allergenIds: string[]) => {
	return [...new Set(allergenIds.map((id) => id.split("-").slice(0, -1)).flat())];
};

export type DietOptions = ReturnType<typeof dietOptions>;
export type AllergenOptions = ReturnType<typeof allergenOptions>;
