import { getAllergenCategories, } from "@edible/shared";
/**
 * Derives filter facts from the active allergen|diet lists:
 * - hasAllergenFilters: boolean indicator if allergen list is populated
 * - hasDietIdsFilters: boolean indicator if diet list is populated
 * - isFilterActive: boolean indicator if either list is populated
 *
 * @param allergens - Active allergen lists
 * @param diets - Active diet list
 * @returns
 */
export const getFilterFacts = (allergens, diets) => {
    const activeAllergenCount = allergens.length;
    const activeDietCount = diets.length;
    const hasAllergenFilters = activeAllergenCount ? true : false;
    const hasDietFilters = activeDietCount ? true : false;
    const isActive = hasAllergenFilters || hasDietFilters;
    const filterCount = activeAllergenCount + activeDietCount;
    return {
        activeAllergenCount,
        activeDietCount,
        filterCount,
        hasAllergenFilters,
        hasDietFilters,
        isActive,
    };
};
export const getDietLists = (diets) => {
    const dietIds = diets.map(({ id }) => id);
    return {
        ids: dietIds,
        entries: diets,
    };
};
/**
 * Derives two views of the filter AllergenEntry list
 * and returns them with the list of entries
 * - `names` = an array of active allergen names
 * - `categories` = an array of active allergen categories
 *
 * @param allergens - The list of active AllergenEntry's
 * @returns
 */
export const getAllergenLists = (allergens) => {
    const allergenIds = allergens.map(({ id }) => id);
    const categories = getAllergenCategories(allergenIds);
    return {
        ids: allergenIds,
        categories: categories,
        entries: allergens,
    };
};
